import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, retry } from 'rxjs/operators';
import { Company } from './company';

@Injectable({
  providedIn: 'root'
})
export class WikimediaService {

  constructor(private http: HttpClient) {
  }

  getImage(logo: string): Observable<string> {
    const apiURL = `https://commons.wikimedia.org/w/api.php?action=query&prop=imageinfo&iiprop=url&redirects&format=json&iiurlwidth=100&titles=${logo}&origin=*`;
    return this.http.get(apiURL).pipe(
      map((res: any) => {
        const pages = res.query.pages;
        const keys = Object.keys(pages);
        return pages[keys[0]] && pages[keys[0]].imageinfo ? pages[keys[0]].imageinfo[0].thumburl : '';
      })
    );
  }

}

