export class Controversy {
  private _title: string;
  private _text: string;

  constructor(type: string, text: string) {
    this._title = type;
    this._text = text;
  }

  get title(): string {
    return this._title;
  }

  get text(): string {
    return this._text;
  }
}
