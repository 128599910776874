import { Company } from './company';

export class Link {
  private _name: string;
  private _reference: string;
  private _company: Company;

  constructor(name: string, reference: string, company: Company) {
    this._name = name;
    this._reference = reference;
    this._company = company;
  }

  public hasLink() {
    return !!this._reference;
  }

  get name(): string {
    return this._name;
  }

  get reference(): string {
    return this._reference;
  }

  get company(): Company {
    return this._company;
  }
}
