import { Link } from './link';
import { Controversy } from './controvery';

type refCompany = {
  name: string;
  reference: string;
  company: Company;
}
type companyParam = {
  id: string,
  title: string,
  wikiLink: string,
  parent: refCompany,
  owner: refCompany,
  logo: string,
  parentTitle: string,
  topPolluter: boolean,
  animalTesting: boolean,
  defunct: boolean,
  hasRelevantInformation: boolean,
  controversies: any[],
  industries: any[],
  subsidiaries: any[]
}

export class Company {
  private _title: string;
  private _wikiLink: string;
  private _parent?: Link;
  private _owner?: Link;
  private _logo: string;
  private _parentTitle: string;
  private _topPolluter: boolean;
  private _animalTesting: boolean;
  private _defunct: boolean;
  private _hasRelevantInformation: boolean;
  private _controversies: Controversy[];
  private _industries: string[];
  private _subsidiaries: Company[];
  private _imgLink: string;
  private _id: string;

  constructor(
   { 
    id,
    title,
    wikiLink,
    parent,
    owner,
    logo,
    parentTitle,
    topPolluter,
    animalTesting,
    defunct,
    hasRelevantInformation,
    controversies,
    industries,
    subsidiaries,
  }: companyParam) {
    const parentName = parent && parent.name;
    const parentReference = parent && parent.reference;
    const ownerName = owner && owner.name;
    const ownerReference = owner && owner.reference;

    this._id = id;
    this._title = title;
    this._wikiLink = wikiLink;
    this._logo = logo;
    this._parentTitle = parentTitle;
    this._topPolluter = topPolluter;
    this._animalTesting = animalTesting;
    this._defunct = defunct;
    this._hasRelevantInformation = hasRelevantInformation;
    this._controversies = controversies ? controversies.map(controversy => new Controversy(controversy.type, controversy.text)) : [];
    this._industries = industries ? industries : [];
    this._subsidiaries =  subsidiaries ? subsidiaries.map(subsidiary => this.getCompany(subsidiary)) : [];
    const parentCompany: Company = parentReference && parent.company ? this.getCompany(parent.company) : null;
    const ownerCompany = ownerReference && owner.company ? this.getCompany(owner.company) : null;
    this._parent = parentName ? new Link(parentName, parentReference, parentCompany) : undefined;
    this._owner = ownerName ? new Link(ownerName, ownerReference, ownerCompany) : undefined;
  }

  isIndependent() {
    return !this.hasParent() && !this.hasUniqueOwner();
  }

  hasSubsidiaries() {
    return this.subsidiaries && this.subsidiaries.length > 0;
  }

  hasIndustries() {
    return this.industries && this.industries.length > 0;
  }


  hasParent() {
    return !!this._parent;
  }

  hasControversies() {
    return this.controversies && this.controversies.length > 0;
  }

  hasUniqueOwner() {
    return !!this._owner && (!this.hasParent() || this._parent.name !== this._owner.name);
  }

  get id(): string {
    return this._id;
  }

  get title(): string {
    return this._title;
  }

  get parent(): Link {
    return this._parent;
  }

  get owner(): Link {
    return this._owner;
  }

  get logo(): string {
    return this._logo;
  }

  get imgLink(): string {
    return this._imgLink;
  }

  set imgLink(value: string) {
    this._imgLink = value;
  }

  get controversies(): Controversy[] {
    return this._controversies;
  }

  set controversies(value: Controversy[]) {
    this._controversies = value;
  }

  get subsidiaries(): Company[] {
    return this._subsidiaries;
  }

  set subsidiaries(value: Company[]) {
    this._subsidiaries = value;
  }

  get topPolluter(): boolean {
    return this._topPolluter;
  }

  set topPolluter(value: boolean) {
    this._topPolluter = value;
  }

  get defunct(): boolean {
    return this._defunct;
  }

  set defunct(value: boolean) {
    this._defunct = value;
  }

  get hasRelevantInformation(): boolean {
    return this._hasRelevantInformation;
  }

  set hasRelevantInformation(value: boolean) {
    this._hasRelevantInformation = value;
  }

  get industries(): string[] {
    return this._industries;
  }

  set industries(value: string[]) {
    this._industries = value;
  }

  get wikiLink(): string {
    return this._wikiLink;
  }

  set wikiLink(value: string) {
    this._wikiLink = value;
  }
  get parentTitle(): string {
    return this._parentTitle;
  }

  set parentTitle(value: string) {
    this._parentTitle = value;
  }

  get animalTesting(): boolean {
    return this._animalTesting;
  }

  set animalTesting(value: boolean) {
    this._animalTesting = value;
  }

  private getCompany(company: any) {
    return new Company(company);
  }
}
