import { environment } from './../../environments/environment';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, Subject, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { Company } from './company';
import { UriConverterService } from './uri.converter.service';
import { SearchType } from './SearchTypes';

@Injectable({
  providedIn: 'root',
})
export class CompanyService implements OnDestroy {
  private unsub$ = new Subject<void>();

  ngOnDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
  }

  constructor(
    private http: HttpClient,
    private uriConverterService: UriConverterService
  ) {}

  getCompany(id: string): Observable<Company> {
    const apiURL = `${environment.backendUrl}company?id=${id}`;
    return this.http.get(apiURL).pipe(
      map((res: any) => {
        return new Company(res);
      })
    );
  }

  getRandomCompany(): Observable<Company> {
    const apiURL = `${environment.backendUrl}company/random`;
    return this.http.get(apiURL).pipe(
      map((res: any) => {
        return new Company(res);
      })
    );
  }

  searchCompaniesByTitle(
    title: string,
    amount: number,
    skip: number
  ): Observable<Company[]> {
    title = this.uriConverterService.encodeKey(title);
    const apiURL = `${environment.backendUrl}company/search?title=${title}&amount=${amount}&skip=${skip}`;
    return this.http.get(apiURL).pipe(
      takeUntil(this.unsub$),
      catchError(err => {
        return [];
      }),
      map((companies: any[]) => {
        return companies.map((res) => new Company(res));
      })
    );
  }

  searchCompaniesByIndustry(
    type: string,
    amount: number,
    skip: number
  ): Observable<Company[]> {
    const apiURL = `${environment.backendUrl}company/industry?type=${type}&amount=${amount}&skip=${skip}`;
    return this.http.get(apiURL).pipe(
      takeUntil(this.unsub$),
      catchError(err => {
        return [];
      }),
      map((companies: any[]) => {
        return companies.map((res) => new Company(res));
      })
    );
  }

  search(
    type: SearchType,
    input: string,
    amount: number,
    skip: number
  ): Observable<Company[]> {
    const functionName = `searchCompaniesBy${type}`;
    if (!(typeof this[functionName] === 'function')) {
      throw new Error(
        `${type} is not a valid type did you forgot to add ${functionName} to the company.service?`
      );
    }

    return this[functionName](input, amount, skip);
  }
}
