import { Injectable } from '@angular/core';
import { HttpParameterCodec } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UriConverterService implements HttpParameterCodec {

  constructor() { }

  decodeKey(key: string): string {
    return "";
  }

  decodeValue(value: string): string {
    return "";
  }

  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
}
